import { types } from 'mobx-state-tree';

export const Video = types
  .model({
    id: types.maybeNull(types.number),
    name: types.optional(types.string, ''),
    ext: types.optional(types.string, ''),
    date: types.optional(types.string, ''),
    duration: types.optional(types.string, ''),
    preview: types.optional(types.string, ''),
    isLoaded: types.optional(types.boolean, false),
    url: types.optional(types.string, ''),
    data: types.frozen([]),
  })
  .views(self => ({
    get idLabel() {
      return self.id;
    },
  }));

export default Video;
