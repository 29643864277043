import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-bottom: 2.25rem;

  > *:not(:first-child) {
    margin-left: 1.5rem;
  }

  > *:last-child {
    margin-left: auto;
  }

  svg {
    cursor: pointer;

    path {
      fill: ${color.GREEN};
      transition: 0.2s ease-out;
    }

    &:hover path {
      fill: ${color.GREEN_120};
    }
  }
`;

export const Time = styled.div`
  display: flex;
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 19px;

  span {
    color: ${color.DARK_BLUE_64};
  }
`;
