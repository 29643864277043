import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Snackbar } from '@151eye/ui';
import { Wrapper } from './styled';

class Snacks extends Component {
  onSnackClose = id => {
    const { SnackbarStore } = this.props;
    SnackbarStore.remove(id);
  };

  render() {
    const { SnackbarStore } = this.props;

    if (!SnackbarStore.list.length) return null;

    return (
      <Wrapper>
        {SnackbarStore.list.map(snack => (
          <Snackbar
            key={snack.id}
            id={snack.id}
            type={snack.type}
            onClose={this.onSnackClose}
          >
            {snack.text}
          </Snackbar>
        ))}
      </Wrapper>
    );
  }
}

export default withRouter(inject('SnackbarStore')(observer(Snacks)));
