import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import DraggableRunner from '../DraggableRunner';
import { Wrapper } from './styled';

class Runner extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onHandleStart: PropTypes.func,
    onHandleStop: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onHandleStart: Function.prototype,
    onHandleStop: Function.prototype,
  };

  handleStart = (e, ui) => {
    const { onHandleStart } = this.props;

    onHandleStart && onHandleStart(ui);
  };

  handleStop = (e, ui) => {
    const { onHandleStop } = this.props;

    onHandleStop && onHandleStop(ui);
  };

  render() {
    const { disabled, x, y } = this.props;

    return (
      <Wrapper>
        <Draggable
          axis="x"
          bounds="parent"
          disabled={disabled}
          defaultPosition={{ x: 0, y: 0 }}
          position={{ x, y }}
          scale={1}
          onStart={this.handleStart}
          onStop={this.handleStop}
        >
          <div style={{ width: 13, cursor: 'pointer' }}>
            <DraggableRunner disabled={disabled} />
          </div>
        </Draggable>
      </Wrapper>
    );
  }
}

export default Runner;
