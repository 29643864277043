import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MobxForm extends Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    children: PropTypes.any,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    autoComplete: 'on',
    onSubmit: Function.prototype,
  };

  render() {
    const { autoComplete, children, onSubmit, ...other } = this.props;

    return (
      <form {...other} autoComplete={autoComplete} onSubmit={onSubmit}>
        {children}
      </form>
    );
  }
}
