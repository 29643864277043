import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border-bottom: 1px solid ${color.LIGHT_WHITE_8};
  min-width: 110%;
`;
