import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  min-height: 425px;
  max-width: 90%;
  transition: 0.5s ease-out;
  opacity: ${props => (props.isHide ? 0 : 1)};
`;
