const defaultExpiresDate = new Date();
defaultExpiresDate.setMonth(defaultExpiresDate.getMonth() + 6);

export default class Cookie {
  static set(name, value, expiresDate = defaultExpiresDate) {
    const expires = `expires=${expiresDate.toUTCString()}`;

    if (typeof document !== 'undefined') {
      document.cookie = `${name}=${value};${expires};path=/`;
    }
  }

  static setSession(name, value) {
    if (typeof document !== 'undefined') {
      document.cookie = `${name}=${value};path=/`;
    }
  }

  static get(cName) {
    const name = `${cName}=`;
    const ca =
      typeof document !== 'undefined' ? document.cookie.split(';') : '';

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  static delete(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}
