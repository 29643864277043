import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Row, LeftBlock, RightBlock } from './styled';

const Grid = ({ videosList, video, timeline }) => (
  <Fragment>
    <Row>
      <LeftBlock>{videosList}</LeftBlock>
      <RightBlock>{video}</RightBlock>
    </Row>
    <Row>{timeline}</Row>
  </Fragment>
);

Grid.propTypes = {
  videosList: PropTypes.element,
  video: PropTypes.element,
  timeline: PropTypes.element,
};

export default Grid;
