import styled from 'styled-components';
import * as color from 'consts/colors';

export const Row = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
  min-width: 1px;
  height: ${props => (!!props.size ? 16 * props.size : 40)}px;
  background: ${props =>
    !!props.background ? props.background : color.LIGHT_BLACK};
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${color.WHITE};
`;
