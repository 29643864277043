import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0 1.5rem 0;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 1rem;
  }

  > svg {
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:hover path {
      opacity: 0.7;
    }
  }
`;

export const Logout = styled.div`
  display: flex;

  svg {
    cursor: pointer;

    path {
      fill: ${color.DARK_BLUE_32};
      transition: 0.2s ease-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: ${color.WHITE};
      }
    }
  }
`;
