import MobxFormStore from 'stores/MobxForm';
import {
  required,
  minLength,
  maxLength,
  notCyrillic,
} from 'utils/Form/validators';

import i18n from 'translates';

import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  LOGIN_MAX_LENGTH,
} from 'consts/form';

const fields = [
  {
    name: 'login',
    label: i18n.t.FORM_AUTH_LOGIN_INPUT,
    validators: [required, maxLength(LOGIN_MAX_LENGTH)],
  },
  {
    name: 'password',
    label: i18n.t.FORM_AUTH_PASSWORD_INPUT,
    validators: [
      required,
      notCyrillic,
      minLength(PASSWORD_MIN_LENGTH),
      maxLength(PASSWORD_MAX_LENGTH),
    ],
  },
];

class LoginForm extends MobxFormStore {}
export default new LoginForm({ fields });
