import React from 'react';
import PropTypes from 'prop-types';

import { MinusIcon, ScaleMaxIcon, ScaleMinIcon } from '@151eye/ui';
import Row from './../Row';

import {
  Wrapper,
  Characteristic,
  CharacteristicLabel,
  CharacteristicsWrapper,
  HeaderRow,
  Slider,
  SliderWrapper,
} from './styled';

import * as color from 'consts/colors';
import { CHARACTERISTIC_TYPE_LIST } from 'consts/ui';

const Sidebar = ({ scaleSlider, characteristics }) => (
  <Wrapper>
    <HeaderRow>
      <SliderWrapper>
        <ScaleMinIcon />
        <Slider>{scaleSlider}</Slider>
        <ScaleMaxIcon />
      </SliderWrapper>
    </HeaderRow>
    <CharacteristicsWrapper>
      {characteristics.map((item, i) => (
        <Row
          key={i}
          background={i % 2 !== 0 && color.LIGHT_WHITE_8}
          size={item.size}
        >
          <Characteristic>
            {item.name}{' '}
            {item.percent && (
              <CharacteristicLabel color={item.percentColor}>
                {item.percent}%
              </CharacteristicLabel>
            )}{' '}
            {item.type === CHARACTERISTIC_TYPE_LIST && <MinusIcon />}
          </Characteristic>
        </Row>
      ))}
    </CharacteristicsWrapper>
  </Wrapper>
);

Sidebar.propTypes = {
  characteristics: PropTypes.array,
  scaleSlider: PropTypes.element,
};

export default Sidebar;
