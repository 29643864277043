import styled from 'styled-components';
import * as color from 'consts/colors';

import runner from './assets/runner.svg';

export const Wrap = styled.div`
  position: relative;
  left: -6px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 472px;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
  transition: all 0.2s ease-out;
`;

export const Line = styled.div`
  height: 100%;
  width: 1px;
  margin-left: 6px;
  background: ${color.GREEN};
`;

export const Runner = styled.div`
  position: absolute;
  top: -19px;
  left: 0;
  width: 13px;
  height: 19px;
  background: url(${runner}) center center no-repeat;
  cursor: pointer;
`;
