import React from 'react';
import PropTypes from 'prop-types';

import { Row } from './styled';

const TimelineRow = ({ children, background, large, ...others }) => (
  <Row background={background} large={large} {...others}>
    {children}
  </Row>
);

TimelineRow.propTypes = {
  background: PropTypes.any,
  large: PropTypes.bool,
};

export default TimelineRow;
