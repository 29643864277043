import styled from 'styled-components';
import * as color from 'consts/colors';

import background from './LeftSide/assets/background.svg';

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

export const InternalWrap = styled.div`
  display: flex;
  height: 100vh;

  > div {
    width: 50%;
  }

  ${props =>
    props.isLogged &&
    `
    animation: fadeOut 0.6s ease-out 0.4s forwards;
    
      > div:first-child {
        animation: fadeOutLeftBlock 0.4s ease-out forwards;
        
        > div{
          animation: fadeOut 0.2s ease-out forwards;
        }
      }
      
      > div:last-child {
        animation: fadeOutRightBlock 0.4s ease-out forwards;
      }
  `};

  @keyframes fadeOut {
    from {
      display: flex;
      opacity: 1;
    }
    to {
      display: none;
      opacity: 0;
    }
  }

  @keyframes fadeOutLeftBlock {
    from {
      width: 50%;
    }
    to {
      width: 0;
    }
  }

  @keyframes fadeOutRightBlock {
    from {
      width: 50%;
    }
    to {
      width: 100%;
    }
  }
`;

export const LeftSideWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url(${background}) no-repeat center center / cover,
    linear-gradient(360deg, #141419 0%, #141419 100%);
  color: ${color.LIGHT_WHITE_64};
  z-index: 1;
  font-family: Ubuntu, sans-serif;

  > div {
    max-width: 484px;
    width: 100%;
  }
`;

export const LeftSideMiddle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  font-weight: 300;

  img {
    margin-bottom: 1.5rem;
  }
`;

export const LeftSideBottom = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: 300;
  text-align: center;

  b {
    font-weight: normal;
  }
`;

export const RightSideWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(360deg, #141419 0%, #141419 100%);

  > div > div {
    max-width: 380px;
    width: 100%;
  }
`;

export const RightSideInternal = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px ${color.LIGHT_BLACK_25},
    inset 1px 0px 0px ${color.LIGHT_WHITE_16};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;

export const FakeDashboardWrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
`;
