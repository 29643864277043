import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { routeList } from 'routes';
import { ThemeProvider } from 'styled-components';

import Snackbar from 'modules/Snackbar';

import * as url from 'consts/urls';

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={{ main: 'dark' }}>
        <Route
          render={() => {
            window.scrollTo(0, 0);
            return null;
          }}
        />
        <Switch>
          {Object.keys(routeList.modules).map(module => (
            <Route {...routeList.modules[module]} key={module} />
          ))}
          <Redirect to={url.NOT_FOUND} />
        </Switch>
        <Snackbar />
      </ThemeProvider>
    );
  }
}

export default withRouter(App);
