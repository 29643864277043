import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Input } from './styled';
import PropTypes from 'prop-types';

class InputAutowidth extends Component {
  static propTypes = {
    symbolWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    symbolWidth: 9,
  };

  constructor(props) {
    super(props);

    this.$input = React.createRef();

    this.state = { value: '' };
  }

  componentDidMount() {
    const { value } = this.props;

    this.setWidth(value);
    this.setState({ value });
  }

  setWidth = value => {
    const { symbolWidth } = this.props;

    if (this.$input.current) {
      this.$input.current.style.width =
        (String(value).length + 1) * symbolWidth + 'px';
    }
  };

  onChange = e => {
    const { onChange } = this.props;
    const value = e.target.value;

    this.setWidth(value);
    this.setState({ value });

    onChange && onChange(value);
  };

  render() {
    return (
      <Input
        {...this.props}
        ref={this.$input}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

export default inject('i18n')(InputAutowidth);
