import ru from './ru';

const languages = {
  ru,
};

class I18nHandler {
  constructor() {
    if (I18nHandler.exists) {
      return I18nHandler.instance;
    }

    I18nHandler.instance = this;
    I18nHandler.exists = true;

    this.t = {};
    this.settings = {
      phone: {
        mask: '',
        validatePhone: Function.prototype,
        handlePhone: Function.prototype,
      },
    };

    this.init();
  }

  init() {
    const lang = languages['ru'];

    this.t = lang.t || {};
    this.settings.phone = lang.phoneData || {};
  }
}

const i18nHandler = new I18nHandler();
export default i18nHandler;
