import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from '../Row';
import Chart from '../ChartRuntime';

import { Wrap } from './styled';

import * as color from 'consts/colors';
import * as ui from 'consts/ui';

class MetricCharts extends Component {
  static propTypes = {
    characteristics: PropTypes.array,
    width: PropTypes.number,
    step: PropTypes.number,
    data: PropTypes.any,
    duration: PropTypes.number,
    firstRulerSegment: PropTypes.number,
    startVisibleTime: PropTypes.number,
  };

  static defaultProps = { data: [] };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      this.props.firstRulerSegment !== nextProps.firstRulerSegment ||
      this.props.width !== nextProps.width
    );
  }

  render() {
    const {
      characteristics,
      width,
      step,
      data,
      duration,
      firstRulerSegment,
      startVisibleTime,
    } = this.props;

    let dur = duration * 1000;
    let lastVisibleTime =
      Math.ceil(startVisibleTime) + step * ui.RULER_BLOCKS_QUANTITY;

    lastVisibleTime = lastVisibleTime > dur ? dur : lastVisibleTime;

    return (
      <Wrap>
        {!!characteristics.length &&
          characteristics.map((item, i) => {
            return (
              <Row
                background={i % 2 !== 0 && color.LIGHT_WHITE_8}
                size={item.size}
              >
                {item.chartType && data[i] && (
                  <Chart
                    name={item.chartName}
                    height={item.height}
                    type={item.chartType}
                    width={width}
                    step={step}
                    data={data[i]}
                    firstRulerSegment={firstRulerSegment}
                    startVisibleTime={startVisibleTime}
                    lastVisibleTime={lastVisibleTime}
                  />
                )}
              </Row>
            );
          })}
      </Wrap>
    );
  }
}

export default MetricCharts;
