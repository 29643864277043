import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
`;
