import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import RouteManager from 'routes/manager';

import Dashboard from 'modules/Dashboard';
import LeftSide from '../Common/LeftSide';
import RightSide from '../Common/RightSide';

import { Wrap, InternalWrap, FakeDashboardWrap } from '../Common/styled';

class AuthWrapper extends Component {
  componentWillMount() {
    if (this.props.UserStore.isLoggedIn()) {
      RouteManager.goToDashboard();
    }
  }
  render() {
    const { isLogged } = this.props.UserStore;
    return (
      <Wrap>
        <InternalWrap isLogged={isLogged}>
          <LeftSide />
          <RightSide>{this.props.children}</RightSide>
        </InternalWrap>
        <FakeDashboardWrap>
          <Dashboard />
        </FakeDashboardWrap>
      </Wrap>
    );
  }
}

export default inject('UserStore')(observer(AuthWrapper));
