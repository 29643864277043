import axios from 'axios';

import Cookie from 'utils/Cookie';

import host from 'consts/host';
import { USER_TOKEN } from 'consts/cookies';

export default class Api {
  static getInstance() {
    if (typeof this.instance === 'undefined') {
      this.instance = new this();
    }

    return this.instance;
  }

  constructor() {
    this.endpoint = host;
    this.instance = null;
    this.fetcher = axios.create({
      baseURL: this.endpoint,
    });

    this.fetcher.defaults.headers.common['Authorization'] = '';
    this.fetcher.interceptors.request.use(config => {
      return config;
    });
    this.fetcher.interceptors.response.use(null, error => {
      console.warn('Interceptor ERROR', error);
      if (error.response && error.response.status === 403) {
        // TODO may be for registration and recovery too
        if (error.config.url !== `${this.endpoint}/login`) {
          // logout
          Cookie.delete(USER_TOKEN);
          window.location.href = '/';
        }

        return Promise.reject(error);
      }

      return Promise.reject(error);
    });
  }

  setAuthHeader = () => {
    const authToken = Cookie.get(USER_TOKEN);

    if (authToken) {
      this.fetcher.defaults.headers.common['Authorization'] = authToken;
    }
  };

  deleteAuthHeader = () => {
    delete this.fetcher.defaults.headers.common.Authorization;
  };

  get(url, params = {}, withAuthHeader = true) {
    !withAuthHeader ? this.deleteAuthHeader() : this.setAuthHeader();

    return this.fetcher({
      method: 'get',
      url,
      params,
    });
  }

  post(url, data, withAuthHeader = true) {
    !withAuthHeader ? this.deleteAuthHeader() : this.setAuthHeader();

    return this.fetcher({
      method: 'post',
      url,
      data,
    });
  }

  put(url, data, withAuthHeader = true) {
    !withAuthHeader ? this.deleteAuthHeader() : this.setAuthHeader();

    return this.fetcher({
      method: 'put',
      url,
      data,
    });
  }

  delete(url, withAuthHeader = true) {
    !withAuthHeader ? this.deleteAuthHeader() : this.setAuthHeader();

    return this.fetcher({
      method: 'delete',
      url,
    });
  }
}
