class Scroll {
  static processElement(
    element,
    coordinate,
    horizontal = false,
    duration = 300,
  ) {
    if (!horizontal) {
    } else {
      const startingX = element.scrollLeft;
      const diff = coordinate - startingX;
      let start = 0;

      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        const time = timestamp - start;
        const percent = Math.min(time / duration, 1);

        // window.scrollTo(0, startingX + diff * percent);
        element.scrollTo(startingX + diff * percent, 0);

        // element.scroll(options)

        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    }
  }

  static process(elementY, duration = 300) {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start = 0;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      const time = timestamp - start;
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  static getOffset(element) {
    if (!element) {
      return { top: 0, left: 0 };
    }

    const rect = element.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  static toNodeElement(element, duration = 300, offsetTop = 0) {
    if (!element) {
      return false;
    }

    const offset = this.getOffset(element);

    this.process(offset.top + offsetTop, duration);
  }
}

export default Scroll;
