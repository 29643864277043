import React, { Component } from 'react';

import SecuredLayout from 'modules/SecuredLayout';

export default WrapperComponent => {
  return class extends Component {
    render() {
      return (
        <SecuredLayout>
          <WrapperComponent {...this.props} />
        </SecuredLayout>
      );
    }
  };
};
