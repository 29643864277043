import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styled';
import { TIMELINE_SEGMENT_WIDTH, RULER_BLOCKS_QUANTITY } from 'consts/ui';

class RulerCanvas extends Component {
  static propTypes = {
    scaleValue: PropTypes.number,
    firstRulerSegment: PropTypes.number,
    labelFormatter: PropTypes.func.isRequired,
  };

  id = 'ruler';

  componentDidMount() {
    this.redrawRuler();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      nextProps.firstRulerSegment !== this.props.firstRulerSegment ||
      nextProps.scaleValue !== this.props.scaleValue
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.redrawRuler();
  }

  redrawRuler = () => {
    const { labelFormatter } = this.props;

    const canvas = document.getElementById(this.id);
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    [...Array(RULER_BLOCKS_QUANTITY).keys()].map(i => {
      const x = i * TIMELINE_SEGMENT_WIDTH + TIMELINE_SEGMENT_WIDTH;
      const text = labelFormatter(i);

      ctx.fillStyle = `rgba(255,255,255, 0.72)`;
      ctx.fillRect(x, 32, 1, 8);
      ctx.fillStyle = `rgba(255,255,255, 0.64)`;
      ctx.font = '11px Verdana';
      ctx.fillText(text, x - (String(text).length * 5.5) / 2, 24);
    });
  };

  render() {
    return (
      <Wrapper>
        <canvas
          id={this.id}
          width={RULER_BLOCKS_QUANTITY * TIMELINE_SEGMENT_WIDTH}
          height={39}
        />
      </Wrapper>
    );
  }
}

export default RulerCanvas;
