import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import MobxForm from 'modules/MobxForm';
import { Wrap, Title, Subtitle, ButtonContainer, Form, Field } from './styled';

const Login = ({
  loginField,
  passwordField,
  button,
  errorText,
  onSubmit,
  i18n,
}) => (
  <Wrap>
    <Title>{i18n.t.UI_AUTH_LOGIN_TITLE}</Title>
    {!errorText ? (
      <Subtitle>{i18n.t.UI_AUTH_LOGIN_SUBTITLE}!</Subtitle>
    ) : (
      <Subtitle error>{errorText}</Subtitle>
    )}
    <MobxForm onSubmit={onSubmit}>
      <Form>
        <Field>{loginField}</Field>
        <Field>{passwordField}</Field>
        <ButtonContainer>{button}</ButtonContainer>
      </Form>
    </MobxForm>
  </Wrap>
);

Login.propTypes = {
  loginField: PropTypes.element,
  passwordField: PropTypes.element,
  forgotPasswordLink: PropTypes.element,
  button: PropTypes.element,
  errorText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default inject('i18n')(Login);
