import moment from 'moment';
import Helpers from 'utils/Helpers';
import PhoneHandler from 'utils/PhoneHandler';
import i18n from 'translates';

import * as regexp from 'consts/regexp';

export function required({ field }) {
  const isValid =
    field.value !== undefined &&
    field.value !== null &&
    String(field.value).trim() !== '';

  return [isValid, i18n.t.VALIDATOR_REQUIRED_FIELD];
}

export function notCyrillic({ field }) {
  const isValid = !/[А-ЯЁ]/gi.test(field.value);

  return [isValid, i18n.t.VALIDATOR_NOT_CYRILLIC];
}

export function maxLength(length = 5) {
  return ({ field }) => {
    const value = String(field.value).replace(/\s(?=\s)/g, '');
    const isValid = value.length <= length;

    return [isValid, `${i18n.t.VALIDATOR_MAX_LENGTH} ${length}`];
  };
}

export function minLength(length = 5) {
  return ({ field }) => {
    const value = String(field.value).replace(/\s(?=\s)/g, '');
    const isValid = !!value && value.length >= length;

    return [isValid, `${i18n.t.VALIDATOR_MIN_LENGTH} ${length}`];
  };
}

export function email({ field }) {
  const isValid = !!field.value && regexp.EMAIL.test(field.value);

  return [isValid, i18n.t.VALIDATOR_INVALID_EMAIL];
}

export function validDate({ field }) {
  const isValid =
    Helpers.onlyNumbers(field.value).length === 8 &&
    moment(field.value, 'DD.MM.YYYY').isValid();

  return [isValid, i18n.t.VALIDATOR_INVALID_DATE];
}

export function validDateNotRequired({ field }) {
  const isDateValid = validDate({ field });
  const isValid = !field.value || isDateValid[0];

  return [isValid, i18n.t.VALIDATOR_INVALID_DATE];
}

export function minAge(age = 16) {
  return ({ field }) => {
    const date = moment(field.value, 'DD.MM.YYYY').startOf('day');
    const dateDiff = moment()
      .startOf('day')
      .diff(date, 'years');

    const isValid = dateDiff >= age;

    return [isValid, `${i18n.t.VALIDATOR_MIN_AGE} - ${age}`];
  };
}

export function maxAge(age = 100) {
  return ({ field }) => {
    const date = moment(field.value, 'DD.MM.YYYY').startOf('day');
    const dateDiff = moment()
      .startOf('day')
      .diff(date, 'years');

    const isValid = dateDiff <= age;

    return [isValid, `${i18n.t.VALIDATOR_MAX_AGE}- ${age}`];
  };
}

export function cellPhoneNumber({ field }) {
  const isValid = !field.value || PhoneHandler.validatePhone(field.value);

  return [isValid, i18n.t.VALIDATOR_INVALID_PHONE];
}
