import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const Main = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 270px;
`;

export const TimelineContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  height: calc(100% + 1.5rem);
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
`;

export const FictiveContainer = styled.div`
  min-width: 100%;
  width: ${props => props.width}px;
  min-height: 1px;
`;

export const VisibleContainer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 512px;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  max-width: ${props => props.maxWidth}px;
  overflow: hidden;
`;

export const RulerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${color.LIGHT_WHITE_8};
  width: 100%;
  min-width: 100%;
  max-width: ${props => props.maxWidth}px;
`;

export const ChartsContainer = styled.div`
  margin-top: 40px;
  overflow: hidden;
  min-width: 100%;
  width: ${props => (props.wide ? props.wide : 0)}px;
`;
