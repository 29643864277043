import moment from 'moment';

import { DATE_FORMAT_FORM_DEFAULT } from 'consts/date';
import {
  TIME_TYPES,
  TIME_TYPE_MILLISECONDS,
  TIME_TYPE_SECONDS,
  TIME_TYPE_MINUTES,
  TIME_TYPE_HOURS,
} from 'consts/app';

export default class Helpers {
  static asDate(date, formatOut = DATE_FORMAT_FORM_DEFAULT, formatIn = null) {
    return moment(date, formatIn).format(formatOut);
  }

  static asPrice(value = '') {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }

  static processDate(date) {
    return date ? date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1') : '';
  }

  static onlyNumbers(value) {
    return String(value).replace(/[^0-9]/g, '');
  }

  static plural(number, one, two, five) {
    number = Math.abs(number);
    number %= 100;

    if (number >= 5 && number <= 20) {
      return five;
    }

    number %= 10;

    if (number === 1) {
      return one;
    }

    if (number >= 2 && number <= 4) {
      return two;
    }

    return five;
  }

  static sortByField(array, fieldName) {
    return array && fieldName
      ? array.sort((a, b) =>
          a[fieldName] > b[fieldName]
            ? 1
            : b[fieldName] > a[fieldName]
            ? -1
            : 0,
        )
      : array;
  }

  static convertMbToBytes(sizeMb) {
    return sizeMb * 1024 * 1000;
  }

  static downloadPDF(pdf, fileName = 'Unknown.pdf') {
    if (!pdf) return null;

    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  static formattedTime(sec = 0) {
    const seconds = parseInt(sec);
    const d = Number(seconds);

    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const ms = (sec - seconds).toFixed(3) * 1000;

    return `${('0' + h).slice(-2)}:${('0' + m).slice(-2)}:${('0' + s).slice(
      -2,
    )}:${('00' + ms).slice(-3)}`;
  }

  static formatTimeOnPreview(sec = 0) {
    const seconds = parseInt(sec);
    const d = Number(seconds);

    let h = Math.floor(d / 3600) || '';
    let m = Math.floor((d % 3600) / 60) || '';
    let s = Math.floor((d % 3600) % 60) || '';

    const arr = [];

    if (h) arr.push(h);

    arr.push(m, s);

    return arr.map(item => ('0' + item).slice(-2)).join(':');
  }

  static convertSecondsTo(seconds = 0, to = 's') {
    let result = 0;

    switch (to) {
      case TIME_TYPES[TIME_TYPE_MILLISECONDS].label:
        result = seconds * 1000;
        break;
      case TIME_TYPES[TIME_TYPE_SECONDS].label:
        result = seconds;
        break;
      case TIME_TYPES[TIME_TYPE_MINUTES].label:
        result = seconds / 60;
        break;
      case TIME_TYPES[TIME_TYPE_HOURS].label:
        result = seconds / 3600;
        break;
      default:
        result = seconds;
        break;
    }

    return result;
  }

  static convertToSeconds(value = 0, from) {
    let result = 0;

    switch (from) {
      case TIME_TYPES[TIME_TYPE_MILLISECONDS].label:
        result = value / 1000;
        break;
      case TIME_TYPES[TIME_TYPE_SECONDS].label:
        result = value;
        break;
      case TIME_TYPES[TIME_TYPE_MINUTES].label:
        result = value * 60;
        break;
      case TIME_TYPES[TIME_TYPE_HOURS].label:
        result = value * 3600;
        break;
      default:
        result = value;
        break;
    }

    return result;
  }
}
