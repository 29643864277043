import React from 'react';
import { inject } from 'mobx-react';

import { Wrap } from './styled';

const VideoContainer = ({ children, isHide }) => (
  <Wrap isHide={isHide}>{children}</Wrap>
);

export default inject('i18n')(VideoContainer);
