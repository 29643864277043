const BACKEND_PRODUCTION = 'https://devbe.f2fgroup.ru';
const BACKEND_DEVELOPMENT = 'https://devbe.f2fgroup.ru';
const BACKEND_STAGE = 'https://devbe.f2fgroup.ru';

const hosts = {
  local: BACKEND_DEVELOPMENT,
  development: BACKEND_DEVELOPMENT,
  production: BACKEND_PRODUCTION,
  stage: BACKEND_STAGE,
};

export default hosts[process.env.REACT_APP_NODE_ENV || 'local'];
