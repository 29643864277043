import { types } from 'mobx-state-tree';

export const User = types
  .model({
    id: types.maybeNull(types.number),
    firstName: types.optional(types.string, ''),
    surname: types.optional(types.string, ''),
    middleName: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    birthday: types.optional(types.string, ''),
    position: types.optional(types.string, ''),
    roleId: types.optional(types.number, 0),
  })
  .views(self => ({
    get fullName() {
      return [self.surname, self.firstName, self.middleName]
        .filter(Boolean)
        .join(' ');
    },

    get shortName() {
      const firstName = self.firstName ? `${self.firstName.charAt(0)}.` : '';
      const middleName = self.middleName ? `${self.middleName.charAt(0)}.` : '';

      return `${self.surname} ${firstName} ${middleName}`;
    },
  }));

export default User;
