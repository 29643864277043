import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import withSecuredLayout from 'hocs/withSecuredLayout';

import VideosList from './containers/VideosList';
import Video from './containers/Video';
import Timeline from './containers/Timeline';
import Grid from './components/Grid';

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlaying: false,
      playerData: null,
      currentTime: 0,
      newTime: 0,
    };
  }

  onSelectVideo = videoData => {
    console.log('onSelectVideo', videoData);
  };

  onLoadVideo = playerData => {
    this.setState({ playerData, currentTime: 0 });
  };

  onPlaying = currentTime => {
    this.setState({ currentTime });
  };

  onStartPlaying = currentTime => {
    this.setState({ isPlaying: true, currentTime });
  };

  onStopPlaying = currentTime => {
    this.setState({ isPlaying: false, currentTime });
  };

  onRunnerChange = newTime => {
    this.setState({ newTime });
  };

  render() {
    const selectedVideo = this.props.VideoStore.selectedVideo;
    const { playerData, isPlaying, currentTime, newTime } = this.state;

    const duration = playerData ? playerData.duration : 0;

    return (
      <Fragment>
        <Grid
          videosList={<VideosList onSelectVideo={this.onSelectVideo} />}
          video={
            <Video
              video={selectedVideo}
              newTime={newTime}
              onLoadVideo={this.onLoadVideo}
              onStartPlaying={this.onStartPlaying}
              onStopPlaying={this.onStopPlaying}
              onPlaying={this.onPlaying}
            />
          }
          timeline={
            <Timeline
              duration={duration}
              currentTime={currentTime}
              isPlaying={isPlaying}
              src={playerData ? playerData.src : ''}
              onRunnerChange={this.onRunnerChange}
            />
          }
        />
      </Fragment>
    );
  }
}

export default withSecuredLayout(
  withRouter(
    inject('AppStore', 'VideoStore', 'UserStore', 'i18n')(observer(Overview)),
  ),
);
