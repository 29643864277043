import * as url from 'consts/urls';

const routeList = {
  modules: {
    login: {
      path: url.LOGIN,
      component: require('modules/pages/Auth/containers/Login').default,
      exact: true,
    },
    homePage: {
      path: url.HOME,
      component: require('modules/pages/Overview').default,
      exact: true,
    },
  },
};

export default routeList;
