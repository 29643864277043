import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Portal, PortalList, Button } from '@151eye/ui';

export default class ButtonSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.wrapper = React.createRef();
    this.rect = null;
  }

  static propTypes = {
    label: PropTypes.string,
    element: PropTypes.any,
    listOfValues: PropTypes.array.isRequired,
    portalWidth: PropTypes.number.isRequired,
    placement: PropTypes.oneOf([
      'bottom-end',
      'bottom-start',
      'bottom',
      'left-end',
      'left-start',
      'left',
      'right-end',
      'right-start',
      'right',
      'top-end',
      'top-start',
      'top',
    ]),
    onSelect: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.rect = this.wrapper.current.getBoundingClientRect();
  }

  onSelect = value => {
    const { onSelect } = this.props;

    onSelect && onSelect(value);
  };

  onOpen = e => {
    e && e.preventDefault();
    e && e.stopPropagation();

    this.rect = this.wrapper.current.getBoundingClientRect();
    this.setState({ isOpen: true });
  };

  onClosePortal = e => {
    e && e.preventDefault();
    e && e.stopPropagation();

    this.setState({ isOpen: false });
  };

  render() {
    const { listOfValues, placement, portalWidth, label } = this.props;
    const { isOpen } = this.state;

    const Element = this.props.element || Button;

    return (
      <Fragment>
        <div
          ref={this.wrapper}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Element onClick={this.onOpen}>{label}</Element>
        </div>
        {isOpen && (
          <Portal>
            <PortalList
              listOfValues={listOfValues}
              rect={this.rect && this.rect}
              onSelect={this.onSelect}
              onClosePortal={this.onClosePortal}
              placement={placement}
              width={portalWidth}
            />
          </Portal>
        )}
      </Fragment>
    );
  }
}
