import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { Wrap, Controls, Logout } from './styled';
import logo from './assets/logo.svg';

const Header = ({ addVideoButton, logoutButton, i18n }) => (
  <Wrap>
    <img src={logo} alt={i18n.t.PROJECT_NAME} />
    <Controls>
      {addVideoButton}
      <Logout>{logoutButton}</Logout>
    </Controls>
  </Wrap>
);

Header.propTypes = {
  addVideoButton: PropTypes.element,
  logoutButton: PropTypes.element,
};

export default inject('i18n')(Header);
