import styled from 'styled-components';

export const Layout = styled.div`
  display: block;
  width: 100%;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1366px;
  margin: 0 auto;
`;
