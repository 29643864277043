import React from 'react';

import { Wrap, Line, Runner } from './styled';

const DraggableRunner = ({ disabled }) => (
  <Wrap disabled={disabled}>
    <Runner />
    <Line />
  </Wrap>
);

export default DraggableRunner;
