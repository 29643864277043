import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
  z-index: 100;
  width: 344px;

  > *:not(:first-child) {
    margin-top: 16px;
  }
`;
