import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrapper = styled.div`
  box-sizing: border-box;
  width: 270px;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  border-bottom: 1px solid ${color.LIGHT_WHITE_8};
`;

export const CharacteristicsWrapper = styled.div`
  border-right: 1px solid ${color.LIGHT_WHITE_4};
`;

export const Characteristic = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.75rem 0 1.5rem;

  > *:last-child {
    margin-left: auto;
  }
`;

export const CharacteristicLabel = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: ${color.BLACK};
  padding: 0.25rem;
  border-radius: 2px;
  background: ${props => (props.color ? props.color : color.DARK_BLUE_32)};
  margin-left: 0.5rem;
`;

export const SliderWrapper = styled(Characteristic)`
  > *:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const Slider = styled.div`
  width: 95px;
`;
