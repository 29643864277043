import React from 'react';
import PropTypes from 'prop-types';

import {
  RightSideWrap as Wrap,
  RightSideInternal as InternalWrap,
} from '../styled';

const RightSide = ({ children }) => (
  <Wrap>
    <InternalWrap>{children}</InternalWrap>
  </Wrap>
);

RightSide.propTypes = {
  children: PropTypes.any,
};

export default RightSide;
