import { types } from 'mobx-state-tree';

import UserService from 'services/UserService';
import User from 'models/User';

import Cookie from 'utils/Cookie';
import { USER_TOKEN } from 'consts/cookies';

const UserStore = types
  .model('UserStore', {
    isUserLogged: types.optional(types.boolean, false),
    isDataLoaded: types.optional(types.boolean, false),
    user: types.optional(User, {}),
    statistics: types.optional(
      types.model({
        companies: types.optional(types.number, 0),
        sessions: types.optional(types.number, 0),
        respondents: types.optional(types.number, 0),
        themes: types.optional(types.number, 0),
        payments: types.optional(types.number, 0),
      }),
      {},
    ),
  })
  .actions(self => {
    return {
      isLoggedIn() {
        return !!Cookie.get(USER_TOKEN);
      },

      setToken(token) {
        Cookie.set(USER_TOKEN, token);
        self.isUserLogged = true;
      },

      deleteToken() {
        Cookie.delete(USER_TOKEN);
        self.isUserLogged = false;
      },

      setDataLoaded(value) {
        self.isDataLoaded = value;
      },

      getProfile() {
        return self.user;
      },

      setProfile(data) {
        self.user = data;
      },

      getStatistics() {
        return self.statistics;
      },

      setStatistics(data) {
        self.statistics = data;
      },

      fetch() {
        return UserService.getLoggedUser()
          .then(response => {
            self.setProfile(response.data);
            self.setDataLoaded(true);

            return self.getProfile();
          })
          .catch(error => Promise.reject(error));
      },
    };
  })
  .views(self => ({
    get isLogged() {
      return self.isUserLogged;
    },
  }));

export default UserStore.create();
