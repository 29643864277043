import styled, { css } from 'styled-components';
import * as color from 'consts/colors';

export const Wrap = styled.div`
  padding: 0;
  max-height: 414px;
  overflow: auto;
  box-sizing: border-box;
  margin-right: ${props => (props.isScrollExist ? '0.5rem' : '0')};
`;

export const ItemPreviewBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  width: 124px;
  height: 100%;
  background: url(${props => props.preview && props.preview}) no-repeat center
    center / cover;
`;

export const ItemDuration = styled.div`
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  padding: 0.25rem;
  background: ${color.BLACK};
  border-radius: 2px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: ${color.WHITE};
`;

export const ItemInfoBlock = styled.div`
  display: block;
  flex: 1;
  box-sizing: border-box;
  padding: 0.75rem 0.75rem 0.75rem 1rem;

  font-family: Lato;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${color.DARK_BLUE_64};
`;

export const ItemInfoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;

  > svg {
    cursor: pointer;
    transition: all 0.2s ease-out;

    path {
      fill: ${color.DARK_BLUE_32};
    }
  }
`;

export const ItemInfoName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Lato;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${color.WHITE};

  span {
    color: ${color.DARK_BLUE_64};
  }
`;

export const selectedItemStyles = css`
  background: ${color.LIGHT_WHITE_80};

  ${ItemInfoBlock} {
    color: ${color.LIGHT_BLACK_64};
  }

  ${ItemInfoName} {
    color: ${color.LIGHT_BLACK_80};

    span {
      color: ${color.LIGHT_BLACK_80};
    }
  }

  ${ItemInfoTitle} {
    svg {
      path {
        transition: all 0.2s ease-out;
        fill: ${color.LIGHT_BLACK_64};
      }

      &:hover {
        path {
          fill: ${color.LIGHT_BLACK_80};
        }
      }
    }
  }
`;

export const Item = styled.div`
  display: flex;
  width: ${props => (props.isScrollExist ? 'calc(100% - 1rem)' : '100%')};
  box-sizing: border-box;
  height: 70px;
  border-radius: 4px;
  background: rgba(
    255,
    255,
    255,
    ${props => (props.isSelected ? '0.8' : '0.08')}
  );
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    ${selectedItemStyles}
  }

  ${props => props.isSelected && selectedItemStyles}
`;
