import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { Wrap, Time } from './styled';

const Header = ({
  playbackButton,
  volumeButton,
  currentTime,
  endTime,
  i18n,
}) => (
  <Wrap>
    {playbackButton}
    {currentTime && (
      <Time>
        {currentTime} <span>/{endTime}</span>
      </Time>
    )}
    {volumeButton}
  </Wrap>
);

Header.propTypes = {
  playbackButton: PropTypes.element,
  volumeButton: PropTypes.element,
  currentTime: PropTypes.string,
  endTime: PropTypes.string,
};

export default inject('i18n')(Header);
