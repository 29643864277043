import { types } from 'mobx-state-tree';

import Snackbar from 'models/Snackbar';

import {
  DEFAULT_TIMEOUT,
  TYPE_ERROR,
  TYPE_NOTICE,
  TYPE_SUCCESS,
} from 'consts/snackbar';

const SnackbarStore = types
  .model('SnackbarStore', {
    list: types.optional(types.array(Snackbar), []),
    counter: 0,
  })
  .actions(self => {
    return {
      add({
        text = '',
        type = TYPE_NOTICE,
        timeout = DEFAULT_TIMEOUT,
        onClose = Function.prototype,
      }) {
        const id = self.counter + 1;
        const snackbar = {
          id,
          type,
          text,
          timeout,
          onClose,
        };

        setTimeout(() => {
          this.remove(id);
        }, timeout);

        self.list.push(snackbar);
        self.counter = id;
      },

      addError({
        text = '',
        timeout = DEFAULT_TIMEOUT,
        onClose = Function.prototype,
      }) {
        this.add({
          text,
          timeout,
          onClose,
          type: TYPE_ERROR,
        });
      },

      addSuccess({
        text = '',
        timeout = DEFAULT_TIMEOUT,
        onClose = Function.prototype,
      }) {
        this.add({
          text,
          timeout,
          onClose,
          type: TYPE_SUCCESS,
        });
      },

      addNotice({
        text = '',
        timeout = DEFAULT_TIMEOUT,
        onClose = Function.prototype,
      }) {
        this.add({
          text,
          timeout,
          onClose,
          type: TYPE_NOTICE,
        });
      },

      remove(id) {
        const newList = self.list.filter(snackbar => snackbar.id !== id);
        self.list = newList;
      },
    };
  });

export default SnackbarStore.create();
