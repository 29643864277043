import { types } from 'mobx-state-tree';
import __get from 'lodash/get';

import SnackbarStore from 'stores/SnackbarStore';

import { DEFAULT_TIMEOUT } from 'consts/snackbar';

const AppStore = types
  .model('AppStore', {
    isMenuMini: types.optional(types.boolean, false),
  })
  .actions(self => {
    return {
      toggleMenu() {
        self.isMenuMini = !self.isMenuMini;
      },

      showErrorRequest(defaultTextError = '', options = {}) {
        return errorObject => {
          const o = Object.assign(
            {},
            {
              path: 'response.data.message',
              callback: Function.prototype,
              showSnack: true,
              timeout: DEFAULT_TIMEOUT,
            },
            options,
          );

          if (o.showSnack) {
            SnackbarStore.addError({
              text: __get(errorObject, o.path) || defaultTextError,
              timeout: o.timeout,
            });
          }

          o.callback && o.callback();
        };
      },
    };
  })
  .views(self => ({
    get isMenuOpen() {
      return !self.isMenuMini;
    },
  }));

export default AppStore.create();
