import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import { Spinner } from 'ui/styled';
import {
  Item,
  ItemPreviewBlock,
  ItemDuration,
  ItemInfoBlock,
  ItemInfoTitle,
  ItemInfoName,
} from './styled';

const ListItem = ({
  children,
  name,
  nameField,
  ext,
  date,
  duration,
  preview,
  actionsButton,
  isScrollExist,
  isLoaded,
  isSelected,
  onClick,
  i18n,
}) => (
  <Item isScrollExist={isScrollExist} onClick={onClick} isSelected={isSelected}>
    <ItemPreviewBlock preview={isLoaded && preview}>
      <Spinner loading={!isLoaded} />
      {isLoaded && <ItemDuration>{duration}</ItemDuration>}
    </ItemPreviewBlock>
    <ItemInfoBlock>
      <ItemInfoTitle>
        <ItemInfoName>
          {name}
          <span>.{ext}</span>
        </ItemInfoName>
        {isLoaded && actionsButton}
      </ItemInfoTitle>
      {isLoaded ? date : `${i18n.t.KEYWORD_LOADING}...`}
    </ItemInfoBlock>
    {children}
  </Item>
);

ListItem.propTypes = {
  name: PropTypes.string,
  nameField: PropTypes.element,
  ext: PropTypes.string,
  date: PropTypes.string,
  duration: PropTypes.string,
  preview: PropTypes.any,
  actionsButton: PropTypes.element,
  isScrollExist: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default inject('i18n')(ListItem);
