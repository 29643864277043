import { types } from 'mobx-state-tree';
import { DEFAULT_TIMEOUT } from 'consts/snackbar';

export const Snackbar = types.model({
  id: types.union(types.string, types.number),
  type: types.integer,
  text: types.optional(types.string, ''),
  timeout: types.optional(types.number, DEFAULT_TIMEOUT),
});

export default Snackbar;
