const t = {
  /* COMMON */
  COMPANY_NAME: 'F2F Group',
  PROJECT_NAME: 'RECEMO',

  BUTTON_SAVE: 'Сохранить',
  BUTTON_ACCEPT: 'Подтвердить',
  BUTTON_SEND: 'Отправить',
  BUTTON_CANCEL: 'Отменить',
  BUTTON_CLOSE: 'Закрыть',
  BUTTON_DOWNLOAD_PDF: 'Скачать в PDF',
  BUTTON_DOWNLOAD_XLS: 'Скачать в XLS',
  BUTTON_ADD_MORE: 'Добавить еще',
  BUTTON_SHOW_ALL: 'Показать все',
  BUTTON_PRINT: 'Печать',
  BUTTON_CHOOSE_FROM_FILE: 'Выбрать из файла',
  BUTTON_CHOOSE_FILE: 'Выбрать файл',
  BUTTON_ADD_VIDEO: 'Добавить видео',

  KEYWORD_YES: 'Да',
  KEYWORD_NO: 'Нет',
  KEYWORD_ALL: 'Все',
  KEYWORD_LOADING: 'Идет загрузка',

  /* MODULES */

  // Module Auth
  UI_AUTH_APP_DESCRIPTION:
    'Платформа для проведения автоматизированных тестирований на основе технологии многослойного анализа поверхности лица',
  UI_AUTH_APP_DEVELOPMENT: 'Разработано в',

  // Auth - Login
  UI_AUTH_LOGIN_TITLE: 'Войти в систему',
  UI_AUTH_LOGIN_SUBTITLE: 'Рады видеть вас снова',
  FORM_AUTH_LOGIN_BUTTON: 'Войти',
  FORM_AUTH_LOGIN_INPUT: 'Логин',
  FORM_AUTH_PASSWORD_INPUT: 'Пароль',

  // Overview
  UI_OVERVIEW_VIDEOS_LIST_VIDEO_ACTION_RENAME: 'Переименовать',
  UI_OVERVIEW_VIDEOS_LIST_VIDEO_ACTION_REMOVE: 'Удалить',

  // Module 404
  UI_404_TITLE: '404 Ошибка',
  UI_404_SUBTITLE:
    'Что-то пошло не так. Попробуйте уточнить запрос или обновить страницу',

  /* UTILS */

  // Form
  // Validators
  VALIDATOR_REQUIRED_FIELD: 'Обязательное поле',
  VALIDATOR_NOT_CYRILLIC: 'Не должно быть кириллицы',
  VALIDATOR_MAX_LENGTH: 'Длина поля не более',
  VALIDATOR_MIN_LENGTH: 'Длина поля не менее',
  VALIDATOR_MIN_VALUE: 'Минимальное значение',
  VALIDATOR_MAX_VALUE: 'Максимальное значение',
  VALIDATOR_INVALID_EMAIL: 'Некорректный e-mail',
  VALIDATOR_NOT_EQUAL: 'Значение не эквивалентно',
  VALIDATOR_INVALID_DATE: 'Некорректная дата',
  VALIDATOR_MIN_AGE: 'Минимальный возраст',
  VALIDATOR_MAX_AGE: 'Максимальный возраст',
  VALIDATOR_MUST_CONTAINS_DIGITS: 'Должен содержать цифр',
  VALIDATOR_INVALID_PHONE: 'Некорректный номер телефона',

  /* ERRORS */

  // Errors - Auth
  ERROR_REQUEST_LOGIN: 'Пользователь не найден!',

  ERROR_REQUEST_RIGHTS_LIMITED: 'Ваши права ограничены для этой операции',

  ERROR_CLIENT_MAX_FILE_SIZE: 'Размер файла не может быть больше',
  ERROR_CLIENT_SUPPORT_FILE_EXTENSIONS: 'Поддерживаются файлы с расширениями',
};

export default {
  t,
  phoneData: {
    mask: '+7 999 999-99-99',
    validatePhone: (phone = '') => {
      return String(phone).replace(/[^0-9]/g, '').length === 11;
    },
    handlePhone: (phone = '') => {
      return String(phone)
        .replace(/[^0-9]/g, '')
        .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 $2 $3 $4 $5');
    },
  },
};
