import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import Cookie from 'utils/Cookie';
import i18n from 'translates';

import { LogoutIcon, AddIcon } from '@151eye/ui';
import Header from './components/Header';
import ListItem from './containers/ListItem';
import InputFile from 'ui/InputFile';

import { Wrap as List } from './components/List/styled';
import { USER_TOKEN } from 'consts/cookies';
import { CHART_TYPE_BAR } from 'consts/app';

import preview from './components/List/preview.svg';

const videos = [
  {
    id: 1,
    name: 'Тест 1',
    ext: 'avi',
    date: '24.09.2019 13:21',
    preview,
    isLoaded: true,
    url: 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4',
    chartType: CHART_TYPE_BAR,
    data: [
      null,
      {
        300: 200,
        1000: 3200,
        5500: 250,
        12400: 1750,
        15000: 300,
        24000: 4300,
        36900: 1300,
        49000: 1300,
      },
      {
        300: 200,
        1000: 3200,
        5500: 250,
        12400: 1750,
        15000: 300,
        24000: 4300,
        36900: 1300,
        49000: 1300,
      },
      {
        300: 200,
        1000: 3200,
        5500: 250,
        12400: 1750,
        15000: 300,
        24000: 4300,
        36900: 1300,
        49000: 1300,
      },
      {
        300: 200,
        1000: 3200,
        5500: 250,
        12400: 1750,
        15000: 300,
        24000: 4300,
        36900: 1300,
        49000: 1300,
      },
      {
        300: 200,
        1000: 3200,
        5500: 250,
        12400: 1750,
        15000: 300,
        24000: 4300,
        36900: 1300,
        49000: 1300,
      },
      [100, 3000, 6700, 6900, 7600, 12300, 25000, 31000, 50000],
      {
        0: 20,
        100: 50,
        200: 60,
        300: 80,
        400: 100,
        500: 70,
        600: 40,
        700: 80,
        800: 20,
        900: 10,
        1000: 50,
        1100: 10,
        1200: 60,
        1300: 80,
        1400: 100,
        1500: 70,
        1600: 40,
        1700: 80,
        1800: 20,
        1900: 70,
        2000: 50,
        2100: 0,
        2200: 60,
        2300: 80,
        2400: 100,
        2500: 20,
        2600: 10,
        2700: 80,
        2800: 10,
        2900: 80,
        3000: 50,
        3100: 10,
        3200: 60,
        3300: 10,
        3400: 100,
        3500: 20,
        3600: 40,
        3700: 20,
        3800: 10,
        3900: 90,
        4000: 50,
        4100: 50,
        4200: 60,
        4300: 80,
        4400: 100,
        4500: 20,
        4600: 10,
        4700: 80,
        4800: 10,
        4900: 20,
        5000: 50,
        5100: 10,
        5200: 90,
        5300: 10,
        5400: 100,
        5500: 20,
        5600: 50,
        5700: 100,
        5800: 30,
        5900: 60,
        6000: 70,
        6100: 10,
        6200: 20,
        6300: 80,
        6400: 80,
        6500: 70,
        6600: 20,
        6700: 50,
        6800: 20,
        6900: 70,
        7000: 30,
      },
      {
        0: { value: 0, next: { start: 100, value: 25 } },
        100: { value: 25, next: { start: 200, value: 35 } },
        200: { value: 35, next: { start: 300, value: 15 } },
        300: { value: 15, next: { start: 400, value: 40 } },
        400: { value: 40, next: { start: 500, value: 55 } },
        500: { value: 55, next: { start: 600, value: 50 } },
        600: { value: 50, next: { start: 700, value: 50 } },
        700: { value: 50, next: { start: 800, value: 56 } },
        800: { value: 56, next: { start: 900, value: 70 } },
        900: { value: 70, next: { start: 1000, value: 35 } },
        1000: { value: 35, next: { start: 1200, value: 45 } },
        1200: { value: 45, next: { start: 1400, value: 35 } },
        1400: { value: 35, next: { start: 1600, value: 35 } },
        1600: { value: 35, next: { start: 1800, value: 14 } },
        1800: { value: 14, next: { start: 2000, value: 30 } },
        2000: { value: 30, next: { start: 2200, value: 80 } },
        2200: { value: 80, next: { start: 2400, value: 85 } },
        2400: { value: 85, next: { start: 2600, value: 65 } },
        2600: { value: 65, next: { start: 2800, value: 70 } },
        2800: { value: 70, next: { start: 3000, value: 80 } },
        3000: { value: 80, next: { start: 4000, value: 30 } },
      },
      {
        200: 400,
        1500: 2500,
        5000: 1000,
        7000: 200,
        9200: 700,
        15000: 2600,
        18000: 1300,
        38000: 2500,
      },
    ],
  },
  {
    id: 2,
    name: 'Тест 2',
    ext: 'avi',
    date: '24.09.2019 13:21',
    preview,
    isLoaded: false,
    url: '',
  },
  {
    id: 3,
    name: 'Тест 3',
    ext: 'avi',
    date: '24.09.2019 13:21',
    preview,
    isLoaded: true,
    url: 'http://peach.themazzone.com/durian/movies/sintel-1024-surround.mp4',
    data: [
      null,
      {
        11000: 5300,
        800000: 3000,
      },
      null,
      null,
      null,
      null,
      [0, 500, 10000, 802305],
      {
        0: 15,
        799800: 45,
        799900: 65,
        800000: 80,
        800100: 55,
      },
    ],
  },
];

class VideosList extends Component {
  static propTypes = {
    onSelectVideo: PropTypes.func,
  };

  static defaultProps = {
    onSelectVideo: Function.prototype,
  };

  constructor(props) {
    super(props);

    this.$list = React.createRef();
    this.state = { isScrollExist: false };
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    const { VideoStore, UserStore } = this.props;
    const isScrollExist = this.isScrollExist();

    const profile = UserStore.getProfile();

    VideoStore.fetch(profile.id).then(() => {
      this.setState({ isScrollExist });
    });

    // Temp
    VideoStore.setList(videos);
    this.setState({ isScrollExist });
  };

  isScrollExist = () => {
    const scrollHeight = this.$list.current
      ? this.$list.current.scrollHeight
      : 0;
    const height = this.$list.current ? this.$list.current.offsetHeight : 0;

    return scrollHeight > height;
  };

  onAddVideo = fileData => {
    console.log('onAddVideo:', fileData);
    if (!fileData) return;

    const { VideoService } = this.props;

    const formData = new FormData();
    formData.append('name', fileData.name);
    formData.append('file', fileData.src);

    VideoService.uploadVideo(formData)
      .then(response => {
        console.log('uploadVideo RESPONSE: ', response);
        this.fetchList();
      })
      .catch(error => {
        console.warn('uploadVideo ERROR: ', error);
      });
  };

  onLogout = () => {
    Cookie.delete(USER_TOKEN);
    window.location.href = '/';
  };

  onSelectVideo = video => {
    const { VideoStore, onSelectVideo } = this.props;

    if (!video.isLoaded) return;

    VideoStore.setVideo(video.id);

    onSelectVideo && onSelectVideo(video);
  };

  render() {
    const { isScrollExist } = this.state;
    const { VideoStore, SnackbarStore, i18n } = this.props;

    const videos = VideoStore.list;
    const selectedVideo = VideoStore.selectedVideo
      ? VideoStore.selectedVideo
      : {};

    return (
      <Fragment>
        <Header
          addVideoButton={
            <InputFile
              icon={<AddIcon />}
              text
              onLoadedEnd={this.onAddVideo}
              onError={error => {
                SnackbarStore.addError({ text: error });
              }}
              maxSize={4096}
              accept={['mp4', 'mov', 'avi']}
              asBase64={false}
            >
              {i18n.t.BUTTON_ADD_VIDEO}
            </InputFile>
          }
          logoutButton={<LogoutIcon onClick={this.onLogout} />}
        />
        <List ref={this.$list} isScrollExist={isScrollExist}>
          {videos.map(item => (
            <ListItem
              {...item}
              key={item.id}
              id={item.id}
              name={item.name}
              onClick={this.onSelectVideo.bind(null, item)}
              isSelected={item.id === selectedVideo.id}
              isParentHasScroll={isScrollExist}
            />
          ))}
        </List>
      </Fragment>
    );
  }
}

export default inject(
  'VideoStore',
  'SnackbarStore',
  'UserStore',
  'VideoService',
  'i18n',
)(observer(VideosList));
