import history from 'routes/history';
import * as url from 'consts/urls';

export default class RouteManager {
  static goTo = ({ url = '', replace = false }) => {
    const target = decodeURIComponent(url);
    replace ? history.replace(target) : history.push(target);
  };

  static goBack = () => {
    if (history.length > 1) {
      history.goBack();
    }
  };

  static goToLogin = replace => {
    RouteManager.goTo({ url: url.LOGIN, replace });
  };

  static goToDashboard = replace => {
    RouteManager.goToHome(replace);
  };

  static goToHome = replace => {
    RouteManager.goTo({ url: url.HOME, replace });
  };

  static historyPush = target => history.push(target);

  static getHistoryLocation = () => history.location;
}
