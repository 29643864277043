import styled from 'styled-components';
import * as color from 'consts/colors';

export const GlobalLoader = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

export const Spinner = styled.div`
  position: absolute;
  left: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid ${color.BLUE};
  margin-left: -14px;
  border-top-color: rgba(0, 0, 0, 0) !important;
  border-radius: 50%;
  transition: 0.3s ease-out;
  opacity: 0;
  animation: loadingSpinner 0.7s infinite linear;

  ${props =>
    props.loading &&
    `
      opacity: 1;
    `}

  @keyframes loadingSpinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
