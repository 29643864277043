import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content, Main } from './styled';

const TimelineArea = ({ children, sidebar }) => (
  <Wrapper>
    <Content>
      {sidebar}
      <Main>{children}</Main>
    </Content>
  </Wrapper>
);

TimelineArea.propTypes = {
  sidebar: PropTypes.element,
};

export default TimelineArea;
