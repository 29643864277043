import styled from 'styled-components';
import * as color from 'consts/colors';

export const Input = styled.input`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  font-family: Lato;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  max-width: 70%;
  color: ${color.WHITE};
`;
