import { types } from 'mobx-state-tree';

import Util from 'models/Util';

const UtilStore = types
  .model('UtilStore', {
    types: types.optional(types.array(Util), []),
    categories: types.optional(types.array(Util), []),
    roles: types.optional(types.array(Util), []),
  })
  .actions(self => {
    return {
      getRoles() {
        return self.roles;
      },

      setRoles(roles) {
        self.roles = roles;
      },

      fetch() {
        return Promise.all([
          // UtilService.getTypes(),
          // UtilService.getThemeCategories(),
          // UtilService.getRoles(),
        ]).then(values => {
          // self.setTypes(values[0].data);
          // self.setCategories(values[1].data);
          // self.setRoles(values[2].data);
        });
      },
    };
  })
  .views(self => ({
    get test() {
      return 'test';
    },
  }));

export default UtilStore.create();
