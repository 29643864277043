import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';

import Helpers from 'utils/Helpers';

import { Button } from '@151eye/ui';
import { InputElement } from './styled';

class InputFile extends Component {
  static propTypes = {
    onLoadedEnd: PropTypes.func,
    onError: PropTypes.func,
    icon: PropTypes.element,
    fileName: PropTypes.string,
    maxSize: PropTypes.number,
    accept: PropTypes.array,
    asBase64: PropTypes.bool,
  };

  static defaultProps = {
    fileName: '',
    accept: [],
    onError: Function.prototype,
    asBase64: true,
  };

  handlerChange = event => {
    const {
      onLoadedEnd,
      onError,
      maxSize,
      accept,
      index,
      i18n,
      asBase64,
    } = this.props;

    const target = event.target;
    const files = target.files;
    const reader = new FileReader();

    if (files && files.length > 0) {
      const file = files[0];
      const fileType = file.name.split(/\.(?=[^\.]+$)/)[1];
      const possibleExtensions = accept.map(item => item.split('/')[0]);

      if (maxSize && file.size > Helpers.convertMbToBytes(maxSize)) {
        onError &&
          onError(`${i18n.t.ERROR_CLIENT_MAX_FILE_SIZE} ${maxSize} MB`);
        return;
      }

      if (
        possibleExtensions.length &&
        possibleExtensions.indexOf(fileType) === -1
      ) {
        onError &&
          onError(
            `${
              i18n.t.ERROR_CLIENT_SUPPORT_FILE_EXTENSIONS
            }: ${possibleExtensions.join(', ')}.`,
          );
        return;
      }

      if (asBase64) {
        reader.onloadend = () => {
          onLoadedEnd &&
            onLoadedEnd({
              target: target,
              src: reader.result,
              name: file.name,
              size: file.size,
              index: index,
            });

          // Clear value after file upload
          target.value = null;
        };

        reader.readAsDataURL(file);
      } else {
        onLoadedEnd && onLoadedEnd({ src: file, name: file.name });
      }
    }
  };

  render() {
    const {
      onError,
      children,
      icon,
      text,
      fileName,
      accept,
      outlined,
    } = this.props;

    return (
      <Button outlined={outlined} icon={icon} text>
        {children}
        <InputElement
          type="file"
          onChange={this.handlerChange}
          onError={onError}
          accept={accept.join(',')}
        />
      </Button>
    );
  }
}

export default inject('i18n')(InputFile);
