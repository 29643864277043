import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Layout, Content } from './styled';

export default class Dashboard extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    const { children } = this.props;

    return (
      <Layout>
        <Content>{children}</Content>
      </Layout>
    );
  }
}
