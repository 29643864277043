import AppStore from './AppStore';
import VideoStore from './VideoStore';
import UserStore from './UserStore';
import UtilStore from './UtilStore';
import SnackbarStore from './SnackbarStore';

export default {
  AppStore,
  VideoStore,
  UserStore,
  UtilStore,
  SnackbarStore,
};
