import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import i18n from 'translates';
import Helpers from 'utils/Helpers';

import { MoreIcon } from '@151eye/ui';
import Item from '../../components/List/Item';
import InputAutowidth from '../../components/InputAutowidth';
import ButtonSelect from 'ui/ButtonSelect';

import { VIDEO_ACTION_RENAME, VIDEO_ACTION_REMOVE } from 'consts/app';

const videoActionsList = [
  {
    label: i18n.t.UI_OVERVIEW_VIDEOS_LIST_VIDEO_ACTION_REMOVE,
    value: VIDEO_ACTION_REMOVE,
  },
];

class ListItem extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    isParentHasScroll: PropTypes.bool,
  };

  state = { editMode: false, duration: 0 };

  componentDidMount() {
    if (this.video) {
      this.video.addEventListener('loadedmetadata', () => {
        this.setState({ duration: this.video.duration });
      });
    }
  }

  onSelectAction = action => {
    if (action.value === VIDEO_ACTION_RENAME) this.setState({ editMode: true });
    if (action.value === VIDEO_ACTION_REMOVE) this.removeVideo();
  };

  onChangeName = name => {
    // this.setState({ name });
  };

  renameVideo = () => {
    const { id } = this.props;
  };

  removeVideo = () => {
    const { id } = this.props;
  };

  render() {
    const {
      id,
      name,
      url,
      onClick,
      isSelected,
      isParentHasScroll,
      VideoStore,
      i18n,
      ...others
    } = this.props;

    const { editMode, duration } = this.state;

    return (
      <Item
        {...others}
        key={id}
        name={name}
        duration={Helpers.formatTimeOnPreview(duration)}
        nameField={
          <InputAutowidth
            value={name}
            onChange={this.onChangeName}
            disabled={!editMode}
            symbolWidth={8.5}
          />
        }
        onClick={onClick}
        isSelected={isSelected}
        isScrollExist={isParentHasScroll}
        actionsButton={
          <ButtonSelect
            element={MoreIcon}
            placement="bottom-start"
            portalWidth={184}
            listOfValues={videoActionsList}
            onSelect={this.onSelectAction}
          />
        }
      >
        <video width="0" height="0" ref={node => (this.video = node)}>
          <source src={url} />
        </video>
      </Item>
    );
  }
}

export default inject('VideoStore', 'i18n')(observer(ListItem));
