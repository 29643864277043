import styled from 'styled-components';
import * as color from 'consts/colors';

export const Row = styled.section`
  display: flex;
  padding: 0;
  margin: 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${color.LIGHT_WHITE_8};
  }
`;

export const LeftBlock = styled.div`
  box-sizing: border-box;
  width: 42%;
  border-right: 1px solid ${color.LIGHT_WHITE_8};
  padding: 1rem 1.5rem 2rem;
`;

export const RightBlock = styled.div`
  box-sizing: border-box;
  width: 58%;
  min-height: 532px;
  padding: 1.75rem 1.5rem 1.5rem;
`;
