import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import RouteManager from 'routes/manager';
import Dashboard from 'modules/Dashboard';

import { GlobalLoader } from 'ui/styled';

class SecuredLayout extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  componentWillMount() {
    const { UserStore, UtilStore } = this.props;

    if (!UserStore.isLoggedIn()) {
      RouteManager.goToLogin();
      return;
    }

    if (!UserStore.isDataLoaded) {
      UserStore.fetch()
        .then(response => {
          // load util data
          UtilStore.fetch();
        })
        .catch(() => {
          UserStore.deleteToken();
          RouteManager.goToLogin();
        });
    }
  }

  render() {
    const { UserStore, children } = this.props;

    return (
      <Fragment>
        {UserStore.isDataLoaded === true ? (
          <Dashboard>{children}</Dashboard>
        ) : (
          <GlobalLoader>Loading...</GlobalLoader>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  inject('UserStore', 'UtilStore')(observer(SecuredLayout)),
);
