import React from 'react';
import { inject } from 'mobx-react';

import {
  LeftSideWrap as Wrap,
  LeftSideMiddle as Middle,
  LeftSideBottom as Bottom,
} from '../styled';

import logo from './assets/logo.svg';

const LeftSide = ({ i18n }) => (
  <Wrap>
    <Middle>
      <img src={logo} alt={i18n.t.PROJECT_NAME} />
      {i18n.t.UI_AUTH_APP_DESCRIPTION}
    </Middle>
    <Bottom>
      {i18n.t.UI_AUTH_APP_DEVELOPMENT} <b>{i18n.t.COMPANY_NAME}</b>
    </Bottom>
  </Wrap>
);

export default inject('i18n')(LeftSide);
