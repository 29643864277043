import Api from './Api';

class UserService extends Api {
  getLoggedUser() {
    return this.get(`/user`);
  }

  getInfo(userId) {
    return this.get(`/user/profile/${userId}`);
  }

  login({ login, password }) {
    return this.post('/login', { username: login, password }, false);
  }
}

export default UserService.getInstance();
