import React, { Component } from 'react';

import AuthWrapper from 'modules/pages/Auth/components/AuthWrapper';

export default WrapperComponent => {
  return class extends Component {
    render() {
      return (
        <AuthWrapper>
          <WrapperComponent {...this.props} />
        </AuthWrapper>
      );
    }
  };
};
