import { types } from 'mobx-state-tree';

import VideoService from 'services/VideoService';
import Video from 'models/Video';

const VideoStore = types
  .model('VideoStore', {
    isDataLoaded: types.optional(types.boolean, false),
    selectedVideo: types.maybeNull(Video),
    list: types.optional(types.array(Video), []),
  })
  .actions(self => {
    return {
      setDataLoaded(value) {
        self.isDataLoaded = value;
      },

      setList(list) {
        self.list = list;
      },

      getList() {
        return self.list;
      },

      setVideo(videoId) {
        const video = self.list.find(video => video.id === videoId);

        if (video) {
          self.selectedVideo = { ...video };
        }
      },

      fetch(authorId) {
        return VideoService.getListByAuthor(authorId)
          .then(response => {
            console.log('Videos FETCH:', response);
            // self.setList(response.data);
            // self.setList([]);
            self.setDataLoaded(true);

            return self.list;
          })
          .catch(error => Promise.reject(error));
      },
    };
  });

export default VideoStore.create();
