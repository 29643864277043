export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 16;
export const LOGIN_MAX_LENGTH = 30;
export const PHONE_NUMBER_LENGTH = 11;

export const FIRST_NAME_MAX_LENGTH = 50;
export const SURNAME_MAX_LENGTH = 50;
export const MIDDLE_NAME_MAX_LENGTH = 70;
export const EMAIL_MAX_LENGTH = 150;
export const PHONE_MAX_LENGTH = 40;
export const POSITION_MAX_LENGTH = 255;

export const PERSON_MIN_AGE = 16;
export const PERSON_MAX_AGE = 100;

export const THEME_NAME_MAX_LENGTH = 255;

//  Masks
export const DATE_MASK = '99.99.9999';
export const PHONE_MASK = '+7 999 999-99-99';
