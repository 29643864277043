import * as ui from 'consts/ui';

// Video actions
export const VIDEO_ACTION_RENAME = 1;
export const VIDEO_ACTION_REMOVE = 2;

// Time

export const TIME_TYPE_MILLISECONDS = 1;
export const TIME_TYPE_SECONDS = 2;
export const TIME_TYPE_MINUTES = 3;
export const TIME_TYPE_HOURS = 4;

export const TIME_TYPES = {
  [TIME_TYPE_MILLISECONDS]: { label: 'ms' },
  [TIME_TYPE_SECONDS]: { label: 's' },
  [TIME_TYPE_MINUTES]: { label: 'm' },
  [TIME_TYPE_HOURS]: { label: 'h' },
};

// Timeline scales (step in milliseconds)
export const SCALES_INFO = {
  1: {
    step: 500,
    speed: 500 / ui.TIMELINE_SEGMENT_WIDTH,
    type: TIME_TYPE_MILLISECONDS,
    labelTimeType: TIME_TYPE_MILLISECONDS,
  },
  2: {
    step: 2000,
    speed: 2000 / ui.TIMELINE_SEGMENT_WIDTH,
    type: TIME_TYPE_MILLISECONDS,
    labelTimeType: TIME_TYPE_SECONDS,
  },
  3: {
    step: 5000,
    speed: 5000 / ui.TIMELINE_SEGMENT_WIDTH,
    type: TIME_TYPE_MILLISECONDS,
    labelTimeType: TIME_TYPE_SECONDS,
  },
};

// Chart types
export const CHART_TYPE_BAR = 1; // blue
export const CHART_TYPE_SINGLE = 2; // yellow
export const CHART_TYPE_GRAPH = 3; // green
export const CHART_TYPE_LINEAR = 4; // red
