import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import InputRange from 'react-input-range';

import Helpers from 'utils/Helpers';
import Scroll from 'utils/Scroll';

import TimelineArea from './components/TimelineArea';
import Sidebar from './components/Sidebar';
import Runner from './components/Runner';
import Ruler from './components/RulerCanvas';
import MetricCharts from './components/MetricCharts';

import {
  TimelineContainer,
  VisibleContainer,
  FictiveContainer,
} from './components/TimelineArea/styled';

import * as color from 'consts/colors';
import {
  CHART_TYPE_BAR,
  CHART_TYPE_SINGLE,
  CHART_TYPE_GRAPH,
  CHART_TYPE_LINEAR,
  SCALES_INFO,
  TIME_TYPE_MILLISECONDS,
  TIME_TYPES,
} from 'consts/app';
import {
  TIMELINE_SEGMENT_WIDTH,
  CHARACTERISTIC_TYPE_LIST,
  CHARACTERISTIC_TYPE_SINGLE,
} from 'consts/ui';

const characteristics = [
  { name: 'Эмоции', type: CHARACTERISTIC_TYPE_LIST, chartName: '' },
  {
    name: 'Злость',
    type: CHARACTERISTIC_TYPE_SINGLE,
    chartType: CHART_TYPE_BAR,
    chartName: 'emotion-anger',
  },
  {
    name: 'Страх',
    type: CHARACTERISTIC_TYPE_SINGLE,
    chartType: CHART_TYPE_BAR,
    chartName: 'emotion-fear',
  },
  {
    name: 'Печаль',
    type: CHARACTERISTIC_TYPE_SINGLE,
    chartType: CHART_TYPE_BAR,
    chartName: 'emotion-sadness',
  },
  {
    name: 'Удивление',
    type: CHARACTERISTIC_TYPE_SINGLE,
    chartType: CHART_TYPE_BAR,
    chartName: 'emotion-surprise',
  },
  {
    name: 'Удивление (соц. желаемое)',
    type: CHARACTERISTIC_TYPE_SINGLE,
    chartType: CHART_TYPE_BAR,
    chartName: 'emotion-surprise-desired',
  },
  {
    name: 'Моргания',
    type: CHARACTERISTIC_TYPE_LIST,
    size: 3.5,
    percent: 16,
    percentColor: color.YELLOW,
    chartType: CHART_TYPE_SINGLE,
    chartName: 'blinking',
    height: 24,
  },
  {
    name: 'Мимическая активность',
    type: CHARACTERISTIC_TYPE_LIST,
    size: 4,
    percent: 36,
    percentColor: color.GREEN,
    chartType: CHART_TYPE_GRAPH,
    chartName: 'mimic-activity',
    height: 64,
  },
  {
    name: 'Активность движения лица',
    type: CHARACTERISTIC_TYPE_LIST,
    size: 4,
    chartType: CHART_TYPE_LINEAR,
    chartName: 'face-movement-activity',
    height: 64,
  },
  {
    name: 'Говорение',
    type: CHARACTERISTIC_TYPE_LIST,
    size: 3,
    percent: 40,
    percentColor: color.BLUE,
    chartType: CHART_TYPE_BAR,
    chartName: 'speaking',
  },
];

class Timeline extends Component {
  static propTypes = {
    isPlaying: PropTypes.bool,
    duration: PropTypes.number, // in seconds
    currentTime: PropTypes.number, // in seconds
    onRunnerChange: PropTypes.func,
  };

  static defaultProps = {
    isPlaying: false,
    duration: 0,
    currentTime: 0,
    onRunnerChange: Function.prototype,
  };

  state = {
    scaleValue: 2,
    firstRulerSegment: 1,
    startVisibleTime: 0,
    runnerPosition: { x: 0, y: 0 },
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Init new data
    if (this.props.src !== prevProps.src) {
      this.setState({ runnerPosition: { x: 0, y: 0 } }, () => {
        if (this._timeContainer) this._timeContainer.scrollLeft = 0;
      });
    }

    // Playback video
    if (prevProps.currentTime !== this.props.currentTime) {
      if (this.props.isPlaying) {
        this.moveOnPlaying();
      }
    }
  }

  onTimelineScroll = e => {
    this.moveManually(e.target.scrollLeft);
  };

  moveOnPlaying = () => {
    const { currentTime } = this.props;
    const { scaleValue } = this.state;

    const container = this.getContainerInfo();
    const leftOffsetByTime = Math.floor(
      (currentTime * 1000) / SCALES_INFO[scaleValue].speed,
    );

    Scroll.processElement(this._timeContainer, leftOffsetByTime, true, 500);

    // Move runner if block scrolled completely
    if (
      Math.ceil(this._timeContainer.scrollLeft) +
        this._timeContainer.offsetWidth >=
      container.width
    ) {
      let runnerPositionX = leftOffsetByTime - this._timeContainer.scrollLeft;
      runnerPositionX = runnerPositionX < 0 ? 0 : runnerPositionX;

      this.setState({
        runnerPosition: { x: runnerPositionX, y: 0 },
      });
    }
  };

  moveManually = scrollLeft => {
    const { onRunnerChange, isPlaying } = this.props;
    const { runnerPosition, scaleValue } = this.state;

    const scrolledSegmentsQuantity = scrollLeft / TIMELINE_SEGMENT_WIDTH;
    const scrolledSegmentsQuantityFloor = Math.floor(scrolledSegmentsQuantity);

    const additionalLeftOffset =
      (scrolledSegmentsQuantity - scrolledSegmentsQuantityFloor).toFixed(2) *
      TIMELINE_SEGMENT_WIDTH;

    const container = this.getContainerInfo();
    const sL = scrollLeft - additionalLeftOffset;

    this._visibleContainer.style.width = container.width - sL + 'px';
    this._visibleContainer.style.left = sL + 'px';

    this.setState({
      firstRulerSegment: scrolledSegmentsQuantityFloor + 1,
      startVisibleTime: sL * SCALES_INFO[scaleValue].speed,
    });

    if (!isPlaying)
      onRunnerChange && onRunnerChange(this.getRunnerTime(runnerPosition.x));
  };

  onSliderChange = value => {
    const { VideoStore } = this.props;
    this.setState({ scaleValue: value }, () => {
      if (VideoStore.selectedVideo) this.rebuildScale();
    });
  };

  rebuildScale = () => {
    const { currentTime } = this.props;
    const { runnerPosition, scaleValue } = this.state;

    const container = this.getContainerInfo();
    const leftOffsetByTime = Math.floor(
      (currentTime * 1000) / SCALES_INFO[scaleValue].speed,
    );
    const scrollLeft = leftOffsetByTime - runnerPosition.x;

    this._visibleContainer.style.left = scrollLeft + 'px';
    this._visibleContainer.style.width =
      (scrollLeft === 0
        ? this._timeContainer.offsetWidth
        : container.width - scrollLeft) + 'px';
    this._timeContainer.scrollLeft =
      container.width <= this._timeContainer.offsetWidth ? 0 : scrollLeft;
  };

  onRunnerChange = ui => {
    const { onRunnerChange } = this.props;
    const time = this.getRunnerTime(ui.x);

    this.setState({ runnerPosition: { x: ui.x, y: ui.y } }, () => {
      onRunnerChange && onRunnerChange(time);
    });
  };

  getRunnerTime = leftOffset => {
    const { scaleValue } = this.state;

    const scaleInfo = SCALES_INFO[scaleValue];
    const distance = this._timeContainer.scrollLeft + leftOffset;

    return (distance * scaleInfo.speed) / 1000;
  };

  getContainerInfo = () => {
    const { scaleValue } = this.state;
    const { duration } = this.props;

    const scaleInfo = SCALES_INFO[scaleValue];

    if (!scaleInfo) return { width: 0, segments: 0 };

    const convertedDuration = Helpers.convertSecondsTo(
      duration,
      TIME_TYPES[scaleInfo.type].label,
    );

    const segments = Math.ceil(convertedDuration / scaleInfo.step);

    return { width: segments * TIMELINE_SEGMENT_WIDTH, segments };
  };

  formatRulerLabel = index => {
    const { scaleValue, firstRulerSegment } = this.state;
    const scaleInfo = SCALES_INFO[scaleValue];

    let value = (index + firstRulerSegment) * scaleInfo.step;

    if (scaleInfo.type !== scaleInfo.labelTimeType) {
      value = Helpers.convertSecondsTo(
        Helpers.convertToSeconds(value, TIME_TYPES[scaleInfo.type].label),
        TIME_TYPES[scaleInfo.labelTimeType].label,
      );

      if (scaleInfo.labelTimeType !== TIME_TYPE_MILLISECONDS) {
        const arr = String(value).split('.');

        value = `${arr[0]}:${('0' + (arr[1] || 0)).slice(-2)}`;
      }
    }

    return `${value}${TIME_TYPES[scaleInfo.labelTimeType].label}`;
  };

  render() {
    const {
      scaleValue,
      firstRulerSegment,
      runnerPosition,
      startVisibleTime,
    } = this.state;
    const { duration, isPlaying, VideoStore } = this.props;

    const container = this.getContainerInfo();
    const sliderValues = Object.keys(SCALES_INFO);

    const data = VideoStore.selectedVideo
      ? VideoStore.selectedVideo.data
      : null;

    return (
      <TimelineArea
        sidebar={
          <Sidebar
            characteristics={characteristics}
            scaleSlider={
              <InputRange
                onChange={this.onSliderChange}
                disabled={isPlaying}
                value={scaleValue}
                step={1}
                minValue={+sliderValues[0]}
                maxValue={+sliderValues[sliderValues.length - 1]}
              />
            }
          />
        }
      >
        {!!duration && (
          <Fragment>
            <Runner
              onHandleStop={this.onRunnerChange}
              disabled={isPlaying}
              x={runnerPosition.x}
              y={runnerPosition.y}
            />
            <TimelineContainer
              ref={ref => (this._timeContainer = ref)}
              onScroll={this.onTimelineScroll}
            >
              <FictiveContainer width={container.width} />
              <VisibleContainer ref={ref => (this._visibleContainer = ref)}>
                <Ruler
                  scaleValue={scaleValue}
                  firstRulerSegment={firstRulerSegment}
                  labelFormatter={this.formatRulerLabel}
                />
                <MetricCharts
                  duration={duration}
                  width={container.width}
                  characteristics={characteristics}
                  step={SCALES_INFO[scaleValue].step}
                  data={data}
                  firstRulerSegment={firstRulerSegment}
                  startVisibleTime={startVisibleTime}
                />
              </VisibleContainer>
            </TimelineContainer>
          </Fragment>
        )}
      </TimelineArea>
    );
  }
}

export default inject('VideoStore')(observer(Timeline));
