import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Player, ControlBar, BigPlayButton } from 'video-react';

import Helpers from 'utils/Helpers';

import { PlayIcon, PauseIcon, VolumeIcon, VolumeOffIcon } from '@151eye/ui';
import Header from './components/Header';
import VideoBlock from './components/Video';

class Video extends Component {
  static propTypes = {
    video: PropTypes.any,
    newTime: PropTypes.number,
    onStartPlaying: PropTypes.func,
    onStopPlaying: PropTypes.func,
    onPlaying: PropTypes.func,
    onLoadVideo: PropTypes.func,
  };

  static defaultProps = {
    newTime: 0,
    onStartPlaying: Function.prototype,
    onStopPlaying: Function.prototype,
    onPlaying: Function.prototype,
    onLoadVideo: Function.prototype,
  };

  constructor(props) {
    super(props);

    this.state = {
      player: {},
      videoSource: '',
    };
  }

  componentDidMount() {
    if (this.player) this.player.subscribeToStateChange(this.handleStateChange);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      onStartPlaying,
      onStopPlaying,
      onPlaying,
      onLoadVideo,
    } = this.props;

    // Video is loaded
    if (
      this.state.player &&
      this.state.player.duration &&
      isNaN(prevState.player.duration)
    ) {
      onLoadVideo && onLoadVideo(this.state.player);
    }

    // Set specific time
    if (prevProps.newTime !== this.props.newTime) {
      this.player.seek(this.props.newTime);
    }

    // Playing video
    if (
      prevState.player.currentTime !== this.state.player.currentTime &&
      this.player.currentTime !== 0
    ) {
      onPlaying && onPlaying(this.state.player.currentTime);
    }

    // Start or stop playing
    if (prevState.player.paused !== this.state.player.paused) {
      !this.state.player.paused
        ? onStartPlaying && onStartPlaying(this.state.player.currentTime)
        : onStopPlaying && onStopPlaying(this.state.player.currentTime);
    }
  }

  togglePlayback = () => {
    const { player } = this.state;
    !player.paused ? this.player.pause() : this.player.play();
  };

  toggleVolume = () => {
    this.player.muted = !this.player.muted;
  };

  handleStateChange = state => {
    this.setState({
      player: state,
    });
  };

  stop = () => {
    const { onLoadVideo } = this.props;
    const { player } = this.state;

    this.player.pause();
    this.player.seek(0);

    onLoadVideo && onLoadVideo(player);
  };

  render() {
    const { player } = this.state;
    const {
      VideoStore: { selectedVideo },
    } = this.props;

    const PlaybackButton = player && !player.paused ? PauseIcon : PlayIcon;
    const VolumeButton = player && !player.muted ? VolumeIcon : VolumeOffIcon;

    const currentTime = Helpers.formattedTime(player.currentTime || 0);
    const endTime = Helpers.formattedTime(player.duration || 0);
    const url = selectedVideo ? selectedVideo.url : '';

    return (
      <Fragment>
        {selectedVideo && (
          <Header
            playbackButton={<PlaybackButton onClick={this.togglePlayback} />}
            volumeButton={<VolumeButton onClick={this.toggleVolume} />}
            currentTime={currentTime}
            endTime={endTime}
          />
        )}
        <VideoBlock isHide={!selectedVideo}>
          <Player
            src={url}
            ref={player => {
              this.player = player;
            }}
          >
            <ControlBar disableCompletely />
            <BigPlayButton position="center" />
          </Player>
        </VideoBlock>
      </Fragment>
    );
  }
}

export default inject('VideoStore', 'i18n')(observer(Video));
