export const TIMELINE_SEGMENT_WIDTH = 81;

export const TIMELINE_GRAPH_HEIGHT = 64;

export const CHARACTERISTIC_TYPE_LIST = 1;
export const CHARACTERISTIC_TYPE_SINGLE = 2;

/*
 Max canvas width:
 Google Chrome - 32,767 px
 Firefox - 32,767 px
 
 So, TIMELINE_SEGMENT_WIDTH * 400 = 32400;
 */
export const MAX_CANVAS_SEGMENTS_NUMBER = 400;
export const RULER_BLOCKS_QUANTITY = 22;
