import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import form from './formState';
import RouteManager from 'routes/manager';

import withAuth from 'hocs/withAuth';
import { TextField, Button, EyeIcon, EyeClosedIcon } from '@151eye/ui';
import LoginUI from '../../components/Login';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
      isLogging: false,
      error: '',
    };
  }

  togglePasswordField = () => {
    this.setState(state => ({ isPasswordVisible: !state.isPasswordVisible }));
  };

  onSubmit = e => {
    e && e.preventDefault();

    const { UserStore, UtilStore, UserService, i18n } = this.props;

    this.setState({ error: '' });

    form.validate({ showErrors: true }).then(e => {
      if (!form.isValid) return;

      const { login, password } = form.values();

      this.setState({ isLogging: true });

      UserService.login({ login: login.trim(), password })
        .then(response => {
          this.setState({ isLogging: false });

          const token = response.headers && response.headers.authorization;

          if (token) {
            UserStore.setToken(token);
            UserStore.fetch()
              .then(() => {
                UtilStore.fetch();

                setTimeout(() => {
                  this.setState({ isLogging: false });

                  RouteManager.goToDashboard();
                }, 800);
              })
              .catch(() => {
                this.setState({ isLogging: false });
                UserStore.deleteToken();
              });
          }
        })
        .catch(error => {
          this.setState({
            error: i18n.t.ERROR_REQUEST_LOGIN,
            isLogging: false,
          });
        });
    });
  };

  goToRecovery = () => {
    RouteManager.goToRecovery();
  };

  render() {
    const { isPasswordVisible, isLogging, error } = this.state;
    const { i18n } = this.props;

    return (
      <LoginUI
        onSubmit={this.onSubmit}
        loginField={<TextField {...form.$('login').bind()} />}
        passwordField={
          <TextField
            {...form.$('password').bind()}
            icon={isPasswordVisible ? <EyeClosedIcon /> : <EyeIcon />}
            onIconEvent={this.togglePasswordField}
            type={isPasswordVisible ? 'text' : 'password'}
          />
        }
        button={
          <Button green disabled={isLogging} loading={isLogging}>
            {i18n.t.FORM_AUTH_LOGIN_BUTTON}
          </Button>
        }
        errorText={error}
      />
    );
  }
}

export default withAuth(
  inject('UserStore', 'UtilStore', 'UserService', 'i18n')(observer(Login)),
);
