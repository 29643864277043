import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  CHART_TYPE_BAR,
  CHART_TYPE_SINGLE,
  CHART_TYPE_GRAPH,
  CHART_TYPE_LINEAR,
} from 'consts/app';
import { BLUE, YELLOW, GREEN, RED } from 'consts/colors';
import {
  TIMELINE_SEGMENT_WIDTH,
  TIMELINE_GRAPH_HEIGHT,
  RULER_BLOCKS_QUANTITY,
} from 'consts/ui';

class ChartRuntime extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    width: PropTypes.number,
    step: PropTypes.number,
    height: PropTypes.number,
    data: PropTypes.any,
    firstRulerSegment: PropTypes.number,
    startVisibleTime: PropTypes.number, // in milliseconds
    lastVisibleTime: PropTypes.number, // in milliseconds
  };

  static defaultProps = {
    height: 8,
    data: null,
  };

  componentDidMount() {
    this.drawLayer();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      nextProps.firstRulerSegment !== this.props.firstRulerSegment ||
      nextProps.width !== this.props.width
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.drawLayer();
  }

  drawLayer = () => {
    const { type } = this.props;

    switch (type) {
      case CHART_TYPE_BAR:
        this.drawBars();
        break;
      case CHART_TYPE_SINGLE:
        this.drawSingles();
        break;
      case CHART_TYPE_GRAPH:
        this.drawGraph();
        break;
      case CHART_TYPE_LINEAR:
        this.drawLinear();
        break;
    }
  };

  fillRect = (ctx, x, y, width, height) => {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + width, y);
    ctx.lineTo(x + width, y - height);
    ctx.lineTo(x, y - height);
    ctx.lineTo(x, y);
    ctx.closePath();
    ctx.fill();
  };

  fillRoundRect = (ctx, x, y, width, height, radius) => {
    if (typeof radius === 'undefined') {
      radius = 5;
    }
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (let side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height,
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    ctx.fill();
  };

  drawBars = () => {
    const {
      name,
      height,
      step,
      data,
      lastVisibleTime,
      startVisibleTime,
    } = this.props;

    const canvas = document.getElementById(name);
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = BLUE;

    if (data && Object.keys(data).length !== 0) {
      const factor = TIMELINE_SEGMENT_WIDTH / step; // Quantity of pixels on 1 milliseconds (because step in milliseconds)

      const rangesKeys = Object.keys(data).map(value => +value);
      const visibleRangesKeys = rangesKeys.filter(
        value => value >= startVisibleTime && value < lastVisibleTime,
      );

      visibleRangesKeys.map(startTime => {
        const x = Math.floor((startTime - startVisibleTime) * factor);
        const width = Math.ceil(data[startTime] * factor);

        this.fillRoundRect(ctx, x, 0, width, height, 2);
      });

      // If first range is divided by left border of visible zone
      let closestLeftKey = null;

      // Try to find first previous range
      for (let i = rangesKeys.length - 1; i >= 0; i--) {
        if (rangesKeys[i] < startVisibleTime) {
          closestLeftKey = rangesKeys[i];
          break;
        }
      }

      // If first range is exist, draw it
      if (closestLeftKey !== null) {
        const x = 0;
        const width = Math.ceil(
          (data[closestLeftKey] + closestLeftKey - startVisibleTime) * factor,
        );

        this.fillRoundRect(ctx, x, 0, width, height, 2);
      }
    }
  };

  drawSingles = () => {
    const {
      name,
      step,
      height,
      data,
      lastVisibleTime,
      startVisibleTime,
    } = this.props;

    const canvas = document.getElementById(name);
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = YELLOW;

    if (data && Object.keys(data).length !== 0) {
      const factor = TIMELINE_SEGMENT_WIDTH / step;

      data
        .filter(value => value >= startVisibleTime && value < lastVisibleTime)
        .map(startTime => {
          this.fillRoundRect(
            ctx,
            Math.floor((startTime - startVisibleTime) * factor),
            0,
            1,
            height,
            2,
          );
        });
    }
  };

  drawGraph = () => {
    const { name, step, data, lastVisibleTime, startVisibleTime } = this.props;

    const canvas = document.getElementById(name);
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = GREEN;

    if (data && Object.keys(data).length !== 0) {
      const factor = TIMELINE_SEGMENT_WIDTH / step;
      const startKey = Math.floor(Math.floor(startVisibleTime) / 100) * 100;

      for (let i = startKey; i < lastVisibleTime; i += 100) {
        this.fillRect(
          ctx,
          Math.floor(i - startVisibleTime) * factor,
          TIMELINE_GRAPH_HEIGHT,
          Math.ceil(factor * 100),
          Math.ceil((TIMELINE_GRAPH_HEIGHT / 100) * data[i]),
        );
      }
    }
  };

  drawLinear = () => {
    const { name, step, data, lastVisibleTime, startVisibleTime } = this.props;

    const canvas = document.getElementById(name);
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = RED;
    ctx.lineCap = 'round';
    ctx.lineWidth = 2;

    if (data && Object.keys(data).length !== 0) {
      const factor = TIMELINE_SEGMENT_WIDTH / step;
      const startKey = Math.floor(Math.floor(startVisibleTime) / 100) * 100;

      ctx.beginPath();

      for (let i = startKey; i < lastVisibleTime; i += 100) {
        if (!data[i] || !data[i].value || !data[i].next) continue;

        const point = data[i];
        const next = data[i].next;

        const x = Math.floor(i - startVisibleTime) * factor;
        const y = Math.ceil(
          (TIMELINE_GRAPH_HEIGHT / 100) * (100 - point.value),
        );

        const xNext = Math.floor(next.start - startVisibleTime) * factor;
        const yNext = Math.ceil(
          (TIMELINE_GRAPH_HEIGHT / 100) * (100 - next.value),
        );

        ctx.moveTo(x, y);
        ctx.lineTo(xNext, yNext);
      }

      ctx.stroke();
    }
  };

  render() {
    const { height, name } = this.props;

    return (
      <canvas
        width={RULER_BLOCKS_QUANTITY * TIMELINE_SEGMENT_WIDTH}
        height={height}
        id={name}
      />
    );
  }
}

export default ChartRuntime;
