export const DARK_BLUE_100 = '#003865';
export const DARK_BLUE_80 = '#446986';
export const DARK_BLUE_64 = '#748797';
export const DARK_BLUE_48 = '#93ACCA';
export const DARK_BLUE_32 = '#C6D3E3';
export const DARK_BLUE_16 = '#E7ECF2';
export const DARK_BLUE_8 = '#F5F6FA';

export const LIGHT_WHITE_4 = 'rgba(255,255,255, 0.04)';
export const LIGHT_WHITE_8 = 'rgba(255,255,255, 0.08)';
export const LIGHT_WHITE_16 = 'rgba(255,255,255, 0.16)';
export const LIGHT_WHITE_32 = 'rgba(255,255,255, 0.32)';
export const LIGHT_WHITE_64 = 'rgba(255,255,255, 0.64)';
export const LIGHT_WHITE_72 = 'rgba(255,255,255, 0.72)';
export const LIGHT_WHITE_80 = 'rgba(255,255,255, 0.8)';

export const LIGHT_BLACK_8 = 'rgba(0,0,0, 0.08)';
export const LIGHT_BLACK_25 = 'rgba(0,0,0, 0.25)';
export const LIGHT_BLACK_64 = 'rgba(0,0,0, 0.64)';
export const LIGHT_BLACK_80 = 'rgba(0,0,0, 0.8)';

export const BLUE = '#008CFF';
export const GREEN = '#3CC46A';
export const YELLOW = '#FEC823';
export const RED = '#EA4E5C';

export const BLUE_120 = '#0082ED';
export const GREEN_120 = '#10B316';
export const YELLOW_120 = '#E0AD0F';
export const RED_120 = '#D82031';

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

export const LIGHT_BLACK = '#1B1C21';

export const GRADIENT = 'linear-gradient(360deg, #27283C 0%, #35395C 100%)';
