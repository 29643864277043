import styled from 'styled-components';
import * as color from 'consts/colors';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: Ubuntu, sans-serif;
  font-size: 38px;
  font-weight: bold;
  line-height: 44px;
  color: ${color.WHITE};
  margin: 0 0 1rem;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 28px;
  color: ${color.LIGHT_WHITE_32};
  margin: 0 0 2rem;

  ${props =>
    props.error &&
    `
    color: ${color.RED};
  `};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Field = styled.div`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:last-child {
    margin-bottom: 0.5rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 240px;
  padding-top: 0.5rem;

  > button {
    width: 240px;
  }
`;
