import { Form, Field } from 'mobx-react-form';
import vjf from 'mobx-react-form/lib/validators/VJF';

const onCheck = field => (e, payload) => {
  field.value = e.target.checked ? true : '';
};

const bindings = {
  default: {
    error: 'errorText',
  },
  textField: ({ $try, field, props }) => ({
    id: $try(props.id, field.id),
    className: $try(props.className, field.error ? 'hasError' : ''),
    name: $try(props.name, field.name),
    errorText: $try(props.errorText, field.error),
    type: $try(props.type, field.type),
    value: $try(props.value, field.value),
    label: $try(props.label, field.label),
    placeholder: $try(props.placeholder, field.placeholder),
    disabled: $try(props.disabled, field.disabled),
    onChange: $try(props.onChange, field.onChange),
    onBlur: $try(props.onBlur, field.onBlur),
    onFocus: $try(props.onFocus, field.onFocus),
    autoFocus: $try(props.autoFocus, field.autoFocus),
  }),
  customCheckbox: ({ $try, field, props }) => ({
    id: $try(props.id, field.id),
    name: $try(props.name, field.name),
    checked: $try(props.value, !!field.value),
    label: $try(props.label, field.label),
    disabled: $try(props.disabled, field.disabled),
    onChange: $try(props.onChange, onCheck(field)),
    onBlur: $try(props.onBlur, field.onBlur),
    onFocus: $try(props.onFocus, field.onFocus),
    value: $try(props.value, field.value),
    errorText: $try(props.errorText, field.error),
  }),
};

export default class MobxFormStore extends Form {
  options() {
    return {
      showErrorsOnSubmit: true,
      validateOnChange: true,
      showErrorsOnReset: false,
      showErrorsOnClear: false,
      showErrorsOnChange: false,
      strictUpdate: true,
      validationDebounceWait: 1,
    };
  }

  bindings() {
    return bindings;
  }

  plugins() {
    return { vjf: vjf() };
  }

  makeField(field) {
    return new CustomField(field);
  }

  resetNested(fieldName, leaveOne = false) {
    const initialValue = this.state.initial.props.values[fieldName];
    const keys = [];

    this.$(fieldName).map(field => {
      keys.push(field.key);
    });

    if (keys.length > 0) {
      keys.forEach(key => {
        this.$(fieldName).del(key);
      });

      if (leaveOne) {
        this.$(fieldName).add(initialValue[0]);
      }
    }
  }
}

class CustomField extends Field {
  onBlur = e => this.showErrors(this.value !== '');
}
