import Api from './Api';

class VideoService extends Api {
  uploadVideo(formData) {
    return this.post(`/video/upload`, formData);
  }

  getListByAuthor(authorId) {
    return this.get(`/video/author/${authorId}`);
  }

  getVideo(videoId) {
    return this.get(`/video/${videoId}`);
  }

  removeVideo(videoId) {
    return this.delete(`/video/${videoId}`);
  }
}

export default VideoService.getInstance();
